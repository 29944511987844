import React from 'react'
import { graphql } from 'gatsby'
import NewImage from "../components/newImage"
import parse from "html-react-parser"
import Seo from "../components/seo"



// import Bio from "../components/bio"
import Layout from '../components/layout'

const ReviewTemplate = ({ data: { reviewArray } }) => {
  // const featuredImage = {
  //   fluid: article.featuredImage?.node?.localFile?.childImageSharp?.fluid,
  //   alt: article.featuredImage?.node?.alt || ``,
  // }
  //console.log('reviewArray', reviewArray)
  const review = reviewArray.edges[0].node
  //console.log('review', review)
  //console.log("myposts=",myposts);

  return (
    <Layout>
      <Seo title={review.companyname + " Review -  - What you should know about " + review.companyname} description={`${review.companyname} Independent Review and ratings. Review covers pros and cons of hosting with ${review.companyname}, uptime and support ratings. Compare ${review.companyname} with other best web hosting companies.`} />
      <article
        className="blog-category"
        itemScope
        itemType="http://schema.org/Article"
      >

      </article>
      <article
        className="blog-article"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{review.companyname} Review</h1>
      
          {/*           <Image
            src="godaddylogo.png"
            className="mx-auto shadow-xl"
            alt="Sunset Image"
            style={{
              border: "10px solid green",
            }}
          />
 */}
          {
            reviewArray.edges.map((reviewnode, index) => {
              const bestprice = !!reviewnode.node.bestprice ? parse(reviewnode.node.bestprice) : ""
              //console.log("usertype=",reviewnode.usertype, reviewnode.usertype==="admin")
              // const logourl = review.logourl
              //   .replace('http://www.findbestwebhosting.com/images/', '/imagesfbwh/')
              //   .replace('https://www.findbestwebhosting.com/images/', '/imagesfbwh/')

              const logourl = review.logourl
                .replace('http://www.findbestwebhosting.com/images/', '')
                .replace('https://www.findbestwebhosting.com/images/', '')
                .replace('gif', 'png')

                const removeStr = ' ' //variable
                const regex = new RegExp(removeStr, 'g') // correct way
                const companyname_with_dashes = review.companyname.replace(regex, '-') // it works
                const companyname_with_dashes_lc = companyname_with_dashes.toLowerCase()

              //console.log("reviewtext=",reviewnode.node.reviewtext)

              const reviewtext = !!reviewnode.node.reviewtext ? parse(reviewnode.node.reviewtext
                .replace('http://www.findbestwebhosting.com/images/', '/imagesfbwh/')
                .replace('https://www.findbestwebhosting.com/images/', '/imagesfbwh/')
                .replace('https://www.findbestwebhosting.com/reviews/img/', '/imagesfbwh/')
                .replace('http://www.findbestwebhosting.com/reviews/img/', '/imagesfbwh/')
                .replace('star.gif"', 'star.gif"  width="72" height="15" ')
                .replace('&nbsp;', ' ')) : ""
              const custsupport = reviewnode.node.custsupport.replace('~/images/', '').replace('gif', 'png')
              const uptime = reviewnode.node.uptime.replace('~/images/', '').replace('gif', 'png')
              const overall_rating = reviewnode.node.overall_rating.replace('~/images/', '').replace('gif', 'png')
              //var split_text = reviewnode.node.reviewtext.split(/<img[^>]*>/);
              //var split_text = reviewnode.node.reviewtext.split(/<img /);
              //console.log("split_text=",split_text)


              const fullreviewtext = !!reviewnode.node.reviewtext ? reviewnode.node.reviewtext : ""
              var split_text;
              var imgarray;
              if (!!fullreviewtext) {
                split_text = fullreviewtext.split(/<img[^>]*>/);
                imgarray = fullreviewtext.match(/<img[^>]*>/g);
                //console.log("split_text=", split_text.length, split_text);
                //if (!!imgarray)  console.log("imgarray=", imgarray.length, imgarray);
              }

              return (
                
                <React.Fragment key={index}>
               
                  {
                    reviewnode.node.usertype === "admin" && !!reviewtext &&
                    (
                      
                      <React.Fragment key={"user" + index}>
                        <h3>Editor Review  - In depth review and ratings of {review.companyname}</h3>


                        <div className="3colwrapper">
                          <section className="threecolumns">

                            <div className="eachcolumn">
                              <a target="mew" rel="nofollow"
                                href={`/visit-web-host.aspx/}`}
                              >
                                {/*                            <img
                                  id="logoimg"
                                  alt={`Signup for  ${review.companyname}`}
                                  src={`${logourl}`}

                                /> */}
                                <NewImage
                                  src={`${logourl}`}
                                  alt={`Signup for  ${review.companyname}`}

                                />
                              </a>
                              <table className="table table-sm">
                                <tbody>
                                  <tr><td>
                                    Support Rating:
                                  </td><td>
                                      <NewImage
                                        src={`${custsupport}`}
                                        alt={``}
                                      />

                                    </td></tr>
                                  <tr><td>
                                    Uptime Rating:
                                    </td><td>

                                      <NewImage
                                        src={`${uptime}`}
                                        alt={``}
                                      />
                                    </td></tr>
                                  <tr>
                                    {!!reviewnode.node.overall_rating &&
                                      <>
                                        <td>Overall Rating:</td>
                                        <td>


                                          <NewImage
                                            src={`${overall_rating}`}
                                            alt={``}
                                          />
                                        </td>
                                      </>
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            {/* 2nd col */}

                            <div className="eachcolumn grey">
                              <h2>Whats good about  {`${review.companyname}`} ?</h2>
                              {parse(`${(reviewnode.node.pluspoints)}`)}
                            </div>

                            {/* 3rd col */}
                            <div className="eachcolumn grey">
                              {
                                !!bestprice &&
                                <h2>Just {bestprice} / Month</h2>
                              }
                            {/*   {!!review.coupontext && parse(review.coupontext)
                              }
                            */}
                              
                              <p>
                                <a target="mew" className="btnctagreen" rel="nofollow" href={`/visit-web-host.aspx/${companyname_with_dashes_lc}/${review.companyid}`}
                                >
                                  Get Started
                                </a>
                              </p>
                            </div>

                          </section>
                        </div>

                        <div className="notify">
                        {                          
                            !!imgarray && split_text.map((textnode, index) => {

                              const imgimdex = index
                              //console.log("index=", index, ",textnode=", textnode)
                              //console.log("index=", index )
                              const currimgsrc=imgimdex < imgarray.length ? imgarray[imgimdex].replace(/.*src="([^"]*)".*/, '$1') :"";
                              
          
                              const currimgfilename= currimgsrc? currimgsrc.match(/[\w-]+\.(gif|jpg|png|txt)/g) : ""
                              
                              //console.log("currimgsrc=",currimgsrc);
                              //console.log("currimgfilename=",currimgfilename[0]);
          
                              const currimg = currimgfilename ? 
                              <NewImage
                                            src={`${currimgfilename[0]}`}
                                            alt={`Signup for  `}
          
                                          />
                              
                              : ""
          
                              
                              return (
                                  <React.Fragment key={imgimdex}>
                                      {parse(textnode)}
                                      {currimg}
                                  </React.Fragment>
                              )
          
          
          
                          })
                        }
                        
                        { !imgarray &&  reviewtext} 

                        <p>
                        <br/><a target="mew" className="btnctagreen" rel="nofollow" href={`/visit-web-host.aspx/${review.companyname}/${review.companyid}`}
                        >
                          Signup
                        </a>
                      </p>
                        </div>
                      </React.Fragment>
                    )
                  }
                  {
                    !!reviewnode.node.Fullusername &&
                    (
                      <React.Fragment key={"user" + index}>
                        <div className="notify">
                        <hr />
                        <h3>User Review</h3>
                        <h6>Review By: {reviewnode.node.Fullusername}</h6>
                        <h6>Review Date: {reviewnode.node.reviewdate}</h6>
                        {parse(reviewnode.node.comments)}

                        <p>
                        <br/><a target="mew" className="btnctagreen" rel="nofollow" href={`/visit-web-host.aspx/${review.companyname}/${review.companyid}`}
                        >
                          Signup
                        </a>
                      </p>
                        </div>
                        
                      </React.Fragment>

                    )
                  }
                </React.Fragment>
              )
            }
            )
          }
        </header>

      </article>
      <hr/>id: {review.companyid}
    </Layout>
  )
}

export default ReviewTemplate

export const pageQuery = graphql`
  query ReviewById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: Int!
  ) {
    # selecting the current article by id
    reviewArray: allMysqlDetailedreviews(
      
      filter: { companyid: { eq: $id } },
      sort: {  fields : [Fullusername] order: [DESC]} 
      
      ) {
      edges {
        node {
          id
          reviewid
          companyid
          usertype
           
          reviewtext
          coupontext
          Fullusername
          reviewtitle
          reviewdate
          reviewsunidid
          snapshoturl
          comments
          coname
          companyname
          recommend
          custsupport
          uptime
          overall_rating
          logourl
          pluspoints
          bestprice
          domainname
        }
      }
    }
  }
`
